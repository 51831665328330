import React, { useEffect, useState } from "react";
import FileDownloadIcon from '@rsuite/icons/FileDownload';

import DopeApi from "../services/DopeApi";
import authApi from "../auth/authApi";
import { useAccount } from "../accounts/accountSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopePlaceholder from "../ui/DopePlaceholder";
import { formatToLocaleDateString } from "../utils/date";
import "./Subscriptions.scss";
import { downloadFile } from "../utils/file";

const accountApi = new DopeApi("account");
const accountAssignmentApi = new DopeApi("account_assignment");

const planClass = {
  "LIL' TASTE": "lil-taste",
  "DOPE": "dope",
  "DOPER": "doper",
  "DOPEST": "dopest",
  "FREEBIE": "freebie",
}

const mainStripeProductId = process.env.REACT_APP_MAIN_STRIPE_PRODUCT;

const getMainSubscription = (accountSubscriptions) => {
  return accountSubscriptions.find(sub => sub.items.data.find(item => item.price.product === mainStripeProductId));
}

const getMainSubscriptionPlan = (subscription) => {
  let plan = {};
  if (subscription) {
    subscription.items.data.forEach(item => {
      if (item.price.product === mainStripeProductId) {
        plan = item.price;
      }
    })
  }

  return plan;
}

const SubscriptionItem = ({ plan, currentSubscription }) => {
  if (!currentSubscription) return null;

  const { nickname, unit_amount } = plan;
  let subscriptionPrice = unit_amount / 100;
  if (currentSubscription.discount?.coupon?.amount_off > 0) {
    subscriptionPrice = subscriptionPrice - currentSubscription.discount.coupon.amount_off / 100;
  } else if (currentSubscription.discount?.coupon?.percent_off > 0) {
    subscriptionPrice = subscriptionPrice - (subscriptionPrice * currentSubscription.discount.coupon.percent_off / 100);
  }

  if (Object.keys(plan).length === 0) {
    return null;
  }

  const downloadDocument = async () => {
    downloadFile("/terms-of-use.pdf", 'terms-of-use.pdf', false);
  };

  return (
    <div className={"flex row space-between subscription-wrapper margin-16-b"}>
      <div className="flex column space-between">
        <span className={`subscription-logo ${planClass[nickname]}`}>{nickname}</span>
        <span className="subscription-start-date">Start Date: {formatToLocaleDateString(new Date(currentSubscription.created * 1000).toDateString(), true)}</span>
      </div>
      <div className="flex column right-align">
        <span className="subscription-price monthly-price">${subscriptionPrice}/{plan.recurring.interval}</span>
        <span className="label optional margin-8-b">(next bill date: {formatToLocaleDateString(new Date(currentSubscription.current_period_end * 1000).toDateString(), true)})</span>

        <span className="helpt-text color-text-blue margin-8-b">{currentSubscription.discount ? `${currentSubscription.discount.coupon.name} discount applied` : null}</span>
        <span className="link-label" onClick={()=> {downloadDocument()}}><FileDownloadIcon /> Download Terms & Services</span>
      </div>
    </div>
  );
}

const SecondarySubscriptionItem = ({ subscription, key }) => {
  const { plan, current_period_end, created } = subscription;
  const { metadata, amount } = plan;
  const { displayName } = metadata;

  return (
    <div className="flex row space-between subscription-wrapper margin-16-b" key={key}>
      <div className="flex column space-between">
        <h5 className="header-5">{displayName}</h5>
        <span className="subscription-start-date">Start Date: {formatToLocaleDateString(new Date(created * 1000).toDateString(), true)}</span>
      </div>
      <div className="flex column align-end">
        <span className="subscription-price monthly-price">${amount / 100}/{plan.interval}</span>
        <span className="label optional color-text-secondary">(next bill date: {formatToLocaleDateString(new Date(current_period_end * 1000).toDateString(), true)})</span>
      </div>
    </div>
  );
}

const SubAccountSubscriptionItem = () => {
  return (
    <div className="border pad text-center">
      <h5 className="header-5 margin-16-b">Your account is managed by an agency</h5>
      <div className="help-text">
        That means you have custom pricing through us. Reach out to your Account Manager to review or change your subscription. You can find your mailer pricing under “Current Pricing”.
      </div>
    </div>
  )
}

const SubscriptionSelector = () => {
  const [subscriptionOptions, setSubscriptionOptions] = useState([])
  const [accountSubscriptions, setAccountSubscriptions] = useState([])
  const [accountExecutive, setAccountExecutive] = useState([]);
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [account, accountActions] = useAccount();

  useEffect(() => {
    fetchSubscriptions();
    fetchAccountExecutive();
  }, []);

  const fetchAccountExecutive = async () => {
    const accountAssignments = await accountAssignmentApi.getMany({
      filters: [{ field: "account_id", value: authApi.getCurrentAccountId(), operator: "=" }],
      scopes: [{name: "account_executives"}]
    })
    setAccountExecutive(accountAssignments[0]);
  }

  const fetchSubscriptions = async () => {
    try {
      const subscriptions = await accountApi.fetchMember(authApi.getCurrentAccountId(), "stripe_subscription_data");
      setSubscriptionOptions(subscriptions.stripe_subscription_options);
      setAccountSubscriptions(subscriptions.account_stripe_subscriptions.map(subscription => subscription));
    } catch (error) {
      console.log(error);
    }
  }

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
    navigator.clipboard.writeText(email).then(() => {
      dopeUIActions.addFlashMessage({header: 'Email copied to clipboard', body: email, type: 'success' });
    }).catch(err => {
      dopeUIActions.addFlashMessage({header: 'Something went wrong', body: 'please try again', type: 'error' });
    });
  }

  const baseSubscription = getMainSubscription(accountSubscriptions);
  const baseSubscriptionPlan = getMainSubscriptionPlan(baseSubscription);
  const secondarySubscriptions = accountSubscriptions.filter(subscription => subscription.plan?.metadata?.displayName);
  const planDisplayOptions = [baseSubscriptionPlan];

  if (account.account_type === "agency_client") {
    return <SubAccountSubscriptionItem />
  }

  return (
    <>
      <div className="margin-40-b">
        {subscriptionOptions.length > 0 ? (
          <div>
            { planDisplayOptions.map(plan => (
              <SubscriptionItem
                key={plan.id}
                plan={plan}
                currentSubscription={baseSubscription}
              />)
            )}
            { secondarySubscriptions.length > 0 && (
              <div>
                { secondarySubscriptions.map(subscription => (
                  <SecondarySubscriptionItem key={subscription.id} subscription={subscription} />
                ))}
              </div>
            )}
          </div>
        ) : (
          <DopePlaceholder type={"image"} rows={5} />
        )
        }
      </div>

      <div className="text-center header-6 non-bold">
        Need to change your subscription level or want to cancel? Contact your
        <span className={`color-text-blue ${accountExecutive ? "clickable" : ""}`} onClick={accountExecutive ? () => handleEmailClick(accountExecutive.user.email) : () => {}}> Account Executive </span>
        or reach out to us at
        <span className="color-text-blue clickable" onClick={() => handleEmailClick("support@dopemarketing.com")}> support@dopemarketing.com </span>
      </div>
    </>
  );
}

export default SubscriptionSelector;
