import React, { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import { Button, Radio, RadioGroup } from "rsuite";
import orderBy from 'lodash/orderBy';

import { useCampaign } from '../campaigns/campaignSlice';
import { useDopeUI } from "../ui/dopeUISlice";
import DopeTable from "../ui/DopeTable";
import EddmZipCodeInput from "./EddmZipCodeInput";
import EddmRouteMapInput from "./EddmRouteMapInput";
import EddmRoutesSummary from "./EddmRoutesSummary";
import { DopeFormGroup } from "../ui/DopeForm";
import { MdApartment, MdMapsHomeWork, MdRoofing } from "react-icons/md";
import Cannot from '../ui/Cannot';
import { Icon } from '@rsuite/icons';
import { BsExclamationCircle } from 'react-icons/bs';
import { useCampaignUI } from '../campaigns/campaignUISlice';

const MINIMUM_ADDRESSES = 500;

const IncomeCell = ({ rowData }) => {
  const medIncome = rowData.MED_INCOME;

  return (
    <div>
      {medIncome ? `$${medIncome.toLocaleString()}` : 'N/A'}
    </div>
  );
};

const ZipCridCell = ({ rowData }) => {
  return (
    <div>
      {rowData.ZIP_CODE}-{rowData.CRID_ID}
    </div>
  );
};

const columns = [
  { type: 'custom_component', label: 'Route', Component: ZipCridCell },
  { type: 'text', label: 'Residential', dataKey: 'RES_CNT', sortable: true },
  { type: 'text', label: 'Business', dataKey: 'BUS_CNT', sortable: true },
  { type: 'text', label: 'Total', dataKey: 'TOT_CNT', sortable: true },
  { type: 'custom_component', label: 'Med. Income', dataKey: 'MED_INCOME', Component: IncomeCell, sortable: true },
];

const audienceTypeInput = { inputType: 'radio_tile', name: 'audience', props: {
    label: 'Audience Type',
    options: [
      { value: 'all', label: 'Business & Residential', icon: MdRoofing },
      { value: 'residential', label: 'Residential Only', icon: MdMapsHomeWork },
      { value: 'commercial', label: 'Business Only', icon: MdApartment },
    ],
  }};

const EddmRoutes = () => {
  const [campaign, campaignActions, options] = useCampaign();
  const [campaignUI, campaignUIActions] = useCampaignUI();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const [mapView, setMapView] = useState(true);
  const [sortColumn, setSortColumn] = useState('MED_INCOME');
  const [sortDirection, setSortDirection] = useState('desc');

  const { eddm } = campaign;
  const { pageTabs } = useOutletContext();
  const { fetching, routes, routeErrors } = options.routesData;
  const { audienceCount } = options.eddmData || { audienceCount: 0 };

  const routesData = orderBy(routes.map(route => route.attributes), [sortColumn], [sortDirection]);

  useEffect(() => {
    if (routeErrors) {
      dopeUIActions.addFlashMessage({ header: 'Could not fetch mail routes', body: 'Please try again later', type: 'error' });
    }
  }, [routeErrors]);

  const selected = (route) => {
    return eddm.zip_crids.includes(route.ZIP_CRID);
  };

  const rowClick = (row) => {
    if (!row.TOT_CNT || row.TOT_CNT === 0) {
      dopeUIActions.addFlashMessage({ header: 'Route has no deliverable addresses', body: 'Please select another route', type: 'warning' });
      return;
    }

    campaignActions.toggleEDDMRoute(row.ZIP_CRID);
  };

  useEffect(() => {
    if (audienceCount > 200) {
      campaignUIActions.setMinimumAudienceError(false);
    }
  }, [audienceCount]);

  const handleAudienceTypeChange = (value) => {
    campaignActions.updateEDDM({ audience: value.audience });
  };

  return (
    <>
      <div className="page">
        {pageTabs}

        <div className="page-header">
          <h2 className="header-2 margin-8-b">Select your Routes</h2>
          <p className="body-1 color-text-secondary">Start by selecting your audience type and then enter in a zip code to start building your EDDM campaign. Note: we require a minumum of 200 addresses.</p>
        </div>

        <div className="margin-bottom">
          <DopeFormGroup
            input={audienceTypeInput}
            onChange={handleAudienceTypeChange}
            values={campaign.eddm}
          />
        </div>

        <EddmZipCodeInput zipCode={eddm.zip_code} onChange={campaignActions.updateEDDMZipCodeData} loading={fetching} />

        {campaignUI.minimumAudienceError && (
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem', padding: '0.5rem 1rem', backgroundColor: '#fef4f5', border: '1px solid #f2737d', borderRadius: '4px', marginTop: '1rem'}}>
            <Icon as={BsExclamationCircle} fill='#bb1725' />
            <p style={{margin: 0}}>We require a minimum of <b>200 addresses</b> per EDDM order. Please update or add more routes to continue.</p>
          </div>
        )}

        <div className="flex row">
          <div className="flex-grow">
            <div className="margin-top">
              <div className="to-right square-border rounded-border-top" style={{ marginBottom: "-1px"}}>
                <RadioGroup inline value={mapView} onChange={value => setMapView(value)}>
                  <Radio value={true}>Map View</Radio>
                  <Radio value={false}>Table View</Radio>
                </RadioGroup>
              </div>
              <div className={fetching ? 'disabled' : ''}>
                {mapView ? <EddmRouteMapInput /> : (
                  <div style={{ marginTop: "-6px" }}>
                    <DopeTable
                      columns={columns}
                      data={routesData}
                      onRowClick={rowClick}
                      selected={selected}
                      sortColumn={sortColumn}
                      sortDirection={sortDirection}
                      onSortColumn={(sortColumn, sortDirection) => {
                        setSortColumn(sortColumn);
                        setSortDirection(sortDirection);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="margin-left margin-top" style={{ width: 275 }}>
            <EddmRoutesSummary />
          </div>

        </div>
      </div>
    </>
  );
}

export default EddmRoutes;
