import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@rsuite/icons/ArowBack";
import Papa from "papaparse";

import { useList } from "./listSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import { useObjectState } from "../hooks/useObjectState";

import DopeContentPage from "../ui/DopeContentPage";
import {Input, SelectPicker, Toggle, Grid, Row, Col} from "rsuite";
import DopePlaceholder from "../ui/DopePlaceholder";
import DopeButton from "../ui/DopeButton";
import AdditionalField from "./AdditionalCSVField";


const headerQuickMap = {
  first_name: ["first_name", "first name", "firstname", "first", "customer first name", "contact first name", "client first name"],
  last_name: ["last_name", "last name", "lastname", "last", "customer last name", "contact last name", "client last name"],
  address_1: ["address", "address_1", "address 1", "address1", "address one", "street", "street_address", "street address", "main address", "customer address", "contact address", "client address"],
  address_2: ["address_2", "address 2", "address2", "address two", "apt", "apt #", "apartment", "unit", "unit #", "suite", "suite #"],
  city: ["city", "home city", "customer city", "contact city", "client city"],
  state: ["state", "home state", "customer state", "contact state", "client state"],
  zip: ["zip", "zip5", "zip-5", "zip_code", "zipcode", "zip code", "postal_code", "postal code", "postal"],
  zip_4: ["zip4", "zip-4", "zip_4", "zip4_code", "zip4 code", "zip 4"],
  latitude: ["latitude", "lat"],
  longitude: ["longitude", "long", "lon", "lng"],
  company_name: ["company", "company_name", "company name", "business", "business name", "organization", "organization name"],
};

const navLinkStyles = {
  fontWeight: 700,
  fontSize: '13px',
  paddingLeft: '20px',
  paddingTop: '20px',
  marginBottom: '-10px',
  cursor: 'pointer',
  color: '#255FDF',
  width: "fit-content",
};

const columnHeaderStyles = {
  fontWeight: 700,
  fontSize: '11px',
  color: '#8793A6',
}

const columnHeaderRowStyles = {
  border: "1px solid #F3F3F3",
  borderRadius: "4px 4px 0px 0px",
  padding: "5px",
  marginBottom: "15px",
}

const inputStyles = {
  margin: '0px 0px 0px 20px',
  maxWidth: "210px",
  fontSize: "11px",
  fontWeight: "700",
  color: "#8793A6"
}

const tabComponents = []

const selectOptions = [
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Address 1", value: "address_1" },
  { label: "Address 2", value: "address_2" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Zip", value: "zip" },
  { label: "Zip-4", value: "zip_4" },
  { label: "Country", value: "country" },
  { label: "Company Name", value: "company_name" },
  { label: "Latitude", value: "latitude" },
  { label: "Longitude", value: "longitude" },
  { label: "Custom Field", value: "extended_attributes" },
  { label: "Lead Date (YYYY-MM-DD)", value: "became_lead_at" },
  { label: "Is Lead", value: "is_lead" },
  { label: "Lead Value", value: "lead_value" },
  { label: "Client Date (YYYY-MM-DD)", value: "became_client_at" },
  { label: "Is Client", value: "is_client" },
  { label: "Client Value", value: "client_value" },
];

const columnStyles = {
  display: "flex",
  alignItems: "center",
};

const listTypeOptions = [{label: "Customers", value: "customers"}, {label: "Prospects", value: "prospects"}, {label: "Leads", value: "leads"}, {label: "Combination", value: "combination"}];

const FieldMapPage = ({ handleSave, headers, handleHeaderSelect, handleDataSelect, fieldMap, setCustomFields, fileHasHeaders, enabledHeaders, listType, setListType }) => {
  const [additionalFields, setAdditionalFields] = useState([])
  const selectedOptions = Object.values(fieldMap)
  const unselectedOptions = selectOptions.filter(option => !selectedOptions.includes(option.value) || option.value === "extended_attributes")

  return (
    <div style={{padding: "0px 60px", display: "flex", flexDirection: "column"}}>
      <h3 style={{ fontWeight: 600, fontSize: "18px", lineHeight: "23px" }}>Let's map out your fields</h3>
      <div style={{ fontWeight: 400, fontSize: "14px", marginBottom: "10px", color: "#6F6F6F"}}>
        <strong>We require:</strong> First Name, Last Name, Address 1, City, State, Zip
      </div>

      <div style={{marginBottom: "24px"}}>
        <h6 style={{ fontWeight: 400, fontSize: "14px", color: "#000000", marginBottom: "10px" }}>Who is on this list?</h6>
        <SelectPicker
          data={listTypeOptions}
          searchable={false}
          style={{width: "265px"}}
          onChange={(value) => setListType(value)}
          value={listType}
          defaultValue={listType}
        />

      </div>


      <Grid style={{width: "100%"}}>
        <Row style={columnHeaderRowStyles}>
          <Col xs={7}>
            <h5 style={{...columnHeaderStyles, ...{marginLeft: "10px"}}}>Current Column Header</h5>
          </Col>
          <Col xs={6}>
            <h5 style={columnHeaderStyles}>Sample Column Content</h5>
          </Col>
          <Col xs={8}>
            <h5 style={columnHeaderStyles}>What can we map this to?</h5>
          </Col>
          <Col xs={3}>
            <h5 style={columnHeaderStyles}>Use Data?</h5>
          </Col>
        </Row>
        {headers && headers.map((header, index) => (
          <Row style={{marginBottom: "10px", display: "flex", alignItems: "center"}} key={index} >
            <Col xs={7} style={columnStyles}>
              <Input
                key={header.header}
                style={inputStyles}
                placeholder={header.header}
                disabled={true}
                value={header.header}
              />
            </Col>
            <Col xs={6} style={columnStyles}>
              <div style={{ fontWeight: 400, fontSize: "14px", color: "#000000"}}>
                {header.sample}
              </div>
            </Col>
            <Col xs={8} style={columnStyles}>
              <SelectPicker
                data={unselectedOptions}
                disabled={fileHasHeaders ? !enabledHeaders[header.header] : !enabledHeaders[index]}
                searchable={false}
                style={{width: "265px"}}
                onChange={(value, event) => handleHeaderSelect(value, header.header, index)}
                value={fileHasHeaders ? fieldMap[header.header] : fieldMap[index]}
                placement="auto"
                renderValue={(value, item) => {
                  return selectOptions.find(option => option.value === value)?.label
                }}
              />
            </Col>
            <Col xs={3} style={columnStyles}>
              <Toggle
                onChange={(checked, event) => handleDataSelect(checked, event, index)}
                checked={fileHasHeaders ? enabledHeaders[header.header] : enabledHeaders[index]}
                value={fileHasHeaders ? header.header : index}
              />
            </Col>
          </Row>
        ))}
        {additionalFields.map((field, index) => (
          <AdditionalField
            index={index}
            inputStyles={inputStyles}
            columnStyles={columnStyles}
            setCustomFields={setCustomFields}
          />
        ))}
      </Grid>

      {/*<div>*/}
      {/*  <DopeButton*/}
      {/*    props={{*/}
      {/*       onClick: () => setAdditionalFields([...additionalFields, ""]),*/}
      {/*      buttonClass: "text-link",*/}
      {/*      label: "+ Add Data",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      <div style={{display: "flex", justifyContent: "flex-end", margin: "20px 20px 0 0"}}>
        <DopeButton
          props={{
            onClick: handleSave,
            buttonClass: "filled",
            label: "Save List",
          }}
        />
      </div>
    </div>
  )
}

const CSVFieldMap = () => {
  const [headers, setHeaders] = useState([]);
  const [listType, setListType] = useState("");
  const [fieldMap, updateFieldMap] = useObjectState({});
  const [enabledHeaders, updateEnabledHeaders] = useObjectState({});
  const [uploading, setUploading] = useState(false);
  const [customFields, setCustomFields] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const [list, actions] = useList();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const csv = location.state.uploadFile
  const fileHasHeaders = location.state.fileHasHeaders

  useEffect(() => {
    if (csv) {
      readHeadersFromFile(csv);
    } else {
      actions.goToUpload();
    }
  }, []);

  const readHeadersFromFile = (csvFile) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      let headers = [];
      let headerValues = [];
      let rowCount = 0;

      Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        step: (results, parser) => {
          if (headers.length === 0) {
            headers = results.meta.fields;
            const sampleData = results.data;
            headerValues = headers.map((header) => {
              return { header: header, sample: sampleData[header] }
            });
            setHeaders(headerValues);
          }

          rowCount++;

          if (rowCount >= 2) {
            parser.abort();
          }
        },
        complete: () => {
          headers.forEach((header) => {
            const parsedHeader = header.toLowerCase().trim();
            if (headerQuickMap.first_name.includes(parsedHeader)) {
              handleFoundHeader('first_name', header);
            }
            if (headerQuickMap.last_name.includes(parsedHeader)) {
              handleFoundHeader('last_name', header);
            }
            if (headerQuickMap.address_1.includes(parsedHeader)) {
              handleFoundHeader('address_1', header);
            }
            if (headerQuickMap.address_2.includes(parsedHeader)) {
              handleFoundHeader('address_2', header);
            }
            if (headerQuickMap.city.includes(parsedHeader)) {
              handleFoundHeader('city', header);
            }
            if (headerQuickMap.state.includes(parsedHeader)) {
              handleFoundHeader('state', header);
            }
            if (headerQuickMap.zip.includes(parsedHeader)) {
              handleFoundHeader('zip', header);
            }
            if (headerQuickMap.zip_4.includes(parsedHeader)) {
              handleFoundHeader('zip_4', header);
            }
            if (headerQuickMap.latitude.includes(parsedHeader)) {
              handleFoundHeader('latitude', header);
            }
            if (headerQuickMap.longitude.includes(parsedHeader)) {
              handleFoundHeader('longitude', header);
            }
            if (headerQuickMap.company_name.includes(parsedHeader)) {
              handleFoundHeader('company_name', header);
            }
            return false;
          });
        }
      });
    };
    reader.readAsText(csvFile);
  };

  const handleFoundHeader = (field, header) => {
    updateFieldMap({ [header]: field });
    updateEnabledHeaders({ [header]: true });
  };

  const handleDataSelect = (checked, event, index) => {
    if (fileHasHeaders) {
      updateEnabledHeaders({ [event.target.value]: checked });
      updateFieldMap({ [event.target.value]: null });
    } else {
      updateEnabledHeaders({ [index]: checked });
      updateFieldMap({ [index]: null });
    }
  };

  const handleHeaderSelect = (value, oldHeader, index) => {
    if (fileHasHeaders) {
      updateFieldMap({ [oldHeader]: value });
    } else {
      updateFieldMap({ [index]: value });
    }
  };

  const handleSave = async () => {
    setUploading(true);
    let convertedCustomFields = {};
    Object.keys(customFields).forEach((key) => {
      Object.keys(customFields[key]).forEach((field) => {
        convertedCustomFields[field] = customFields[key][field];
      });
    });

    const isLeadList = listType === "leads";
    const isClientList = listType === "customers";

    let filteredFieldMap = Object.fromEntries(
      Object.entries(fieldMap).filter(([_key, value]) => value !== null)
    );

    try {
      await actions.save({
        header_map: JSON.stringify(filteredFieldMap),
        source_csv: csv,
        custom_fields: JSON.stringify(convertedCustomFields),
        is_lead_list: isLeadList,
        is_client_list: isClientList,
        file_has_headers: fileHasHeaders
      });
      dopeUIActions.addFlashMessage({ header: list.name, body: 'was successfully uploaded', type: 'success' });
      navigate('/lists_and_contacts');
      setUploading(false);
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: list.name, body: 'could not be uploaded', type: 'error' });
    }
  };

  const handlePrevious = () => {
    actions.goToUpload();
  };

  const navLink = <div onClick={handlePrevious} style={navLinkStyles}>
    <ArrowBackIcon /> Back to List Upload
  </div>

  return (
    <>
      { uploading ?
        <DopeContentPage
          pageContent={<DopePlaceholder type="grid" rows={8} columns={4} />}
          pageTitle={`Uploading ${list.name}...`}
          />
        :
        <DopeContentPage
          pageContent={<FieldMapPage
            handleSave={handleSave}
            headers={headers}
            handleDataSelect={handleDataSelect}
            handleHeaderSelect={handleHeaderSelect}
            setListType={setListType}
            listType={listType}
            fieldMap={fieldMap}
            setCustomFields={setCustomFields}
            enabledHeaders={enabledHeaders}
            fileHasHeaders={fileHasHeaders}
          />}
          tabComponents={tabComponents}
          navLink={navLink}
        />
      }
    </>
  )
}

export default CSVFieldMap;
