import React, { useState } from "react";

import { useNavigate, useLocation } from 'react-router-dom';

import { useQuery2 } from "../../hooks/useQuery";
import { deserializeParams } from "../../services/dopeClient";
import { formatToLocaleDateString } from "../../utils/date";
import DopeApi from "../../services/DopeApi";

import DopeListPage from "../../ui/DopeListPage";
import { handleFilterChange } from "../../utils/fn";
import CampaignTypeCell from "../../campaigns/CampaignTypeCell";
import CampaignDispatchesStatusCell from "../../campaigns/CampaignDispatchesStatusCell";
import CampaignStatusCell from "../../campaigns/CampaignStatusCell";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { useDopeUI } from "../../ui/dopeUISlice";

const campaignTypeOptions = [
  { label: "List Campaign", description: 'Upload a list or choose an existing one and send a campaign to each person on that list.', value: "list", icon: "list_campaign" },
  { label: "Neighborhood Blitz", description: 'Draw a custom shape on a map and send mail to residents or businesses within that shape.', value: "blitz", icon: "blitz" },
  { label: "List Blitz", description: 'Quickly send mail to 25, 50, or more neighbors around a list of addresses.', value: "list_blitz", icon: "list_blitz" },
  { label: "EDDM", description: "Every Door Direct Mail allows you to send a campaign to a mailing route in the neighborhood you want to target.", value: "eddm", icon: "eddm" },
];

const generationTypeOptions = [
  { label: "Automation", value: "AutomationRun" },
  { label: "Manual", value: "User" },
];

const campaignStatusOptions = [
  { label: "Draft", value: "draft" },
  { label: "Scheduled", value: "scheduled" },
  { label: "In Progress", value: "in_progress" },
  { label: "Complete", value: "complete" },
  { label: "Error", value: "generation_error" },
  { label: "Cancelled", value: "cancelled" },
];



const columns = [
  { type: 'checkbox', dataKey: 'id', flexGrow: 1 },
  { type: 'custom_component', width: 44, dataKey: 'audience_generation_type', label: '', Component: CampaignTypeCell },
  { type: 'stacked_text', flexGrow: 2, resizable: true, dataKey: ['name', 'trigger_full_name'], secondaryText: 'Trigger:', label: 'Name', sortable: true, filterable: true, customStyles: { color: "#292B2E" } },
  { type: 'date', width: 150, dataKey: 'created_at', label: 'Created Date', sortable: true, filterable: false, dateConverter: formatToLocaleDateString },
  { type: 'custom_component', flexGrow: 2, resizable: true, label: 'Mail Sendings', dataKey: 'dispatch_statuses', Component: CampaignDispatchesStatusCell },
  { type: 'custom_component', width: 180, label: 'Status', dataKey: 'effective_status', Component: CampaignStatusCell },
];

const api = new DopeApi('campaign');
const accountApi = new DopeApi('account');

const InformedVisibility = () => {
  const location = useLocation();
  const [dopeUi, dopeUiActions] = useDopeUI();

  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);

  const searchParams = deserializeParams(location.search);
  const initialScopes = searchParams.initialScopes || [];

  const {
    error,
    total,
    tableProps,
    paginationProps,
    filters,
    setFilters,
    scopes,
    setScopes,
    onSearchClear,
    onSearchInput,
  } = useQuery2({
    api,
    initialSearchScope: 'name',
    initialScopes: [{ name: 'in_progress_or_complete' }]
  });

  const applyGenerationTypeFilter = (generationType) => {
    const filterObject = {
      filterField: 'creator_type',
      operator: "in",
      filterCategory: "Trigger Type",
    };
    handleFilterChange(filters, setFilters, generationType, filterObject);
  };

  const applyAccountsFilter = (accountIds) => {
    const filterObject = {
      filterField: "account_id",
      operator: "in",
      filterCategory: "Account",
    };

    handleFilterChange(filters, setFilters, accountIds, filterObject);
  };

  const selectedActions = [
    {
      label: "Generate Report", action: (selectedIds) => {
        console.log(selectedIds);
        api.rawPost('informed_visibility', { campaign_ids: selectedIds }).then(() => {
          dopeUiActions.addFlashMessage({ header: "Generating Report", body: `The informed visibility report for ${selectedIds.length} campaigns will be sent to your email.`, type: "success" });
        }).catch(() => {
          dopeUiActions.addFlashMessage({ header: "Error", body: 'An unknown error ocurred.', type: "error" });
        });
      }, icon: <BsFileEarmarkBarGraph style={{ fontSize: "12px", marginRight: "4px" }} />
    },
  ];

  const filterInputs = [
    {
      title: "Account",
      type: "MultiSelect",
      name: "account_ids",
      field: "account_id",
      searchable: true,
      getOptions: (text = "") => accountApi.getOptions({
        valueKey: 'id',
        labelKey: 'name',
        search: { text, column: 'name' },
      }),
      applyFilter: applyAccountsFilter,
    },
    { title: "Status", type: "scopeArgs", options: campaignStatusOptions, name: "by_campaign_status", singleSelect: true },
    { title: "Campaign Type", type: "scopeArgs", options: campaignTypeOptions, name: "by_audience_generation_type_keys" },
    { title: "Trigger Type", getOptions: () => generationTypeOptions, applyFilter: applyGenerationTypeFilter },
  ];

  return (
    <div className="">
      <div className="page-header">
        <p className="header-text">Informed Visibility</p>
        <p className="help-text">{total} Campaigns</p>
      </div>

      <DopeListPage
        paginationProps={paginationProps}
        tableProps={tableProps}
        columns={columns}
        error={error}
        filterInputs={filterInputs}
        filters={filters}
        setFilters={setFilters}
        scopes={scopes}
        setScopes={setScopes}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
        rowHeight={65}
        selectedActions={selectedActions}
      />

    </div>
  );
};

export default InformedVisibility;
