import React from "react";
import { useLocation } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";

import DopeTabs from "../../ui/DopeTabs";

const reportingPages = [
  { name: "data_axle_usage", label: "Data Axle Usage" },
  { name: "delayed_jobs", label: "Job Queue" },
  { name: "informed_visibility", label: "Informed Visibility" }
];

const PulseReporting = () => {
  const navigate = useNavigate();
  const childTab = useLocation().pathname.split("/").pop();

  const tabs = reportingPages.map((page) => <DopeTabs.Tab key={page.name} name={page.name} label={page.label} />);
  const onTabChange = (tab) => {
    navigate(`/pulse/reporting/${tab}`);
  };

  return (
    <div className="page">
      <div className="header-2 margin-bottom">Reporting</div>

      <DopeTabs defaultTab={childTab} onChange={onTabChange}>
        {tabs}
      </DopeTabs>

      <Outlet />
    </div>
  );
};

export default PulseReporting;
