import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar, Sidenav, Nav, Navbar } from "rsuite";
import { Icon } from '@rsuite/icons';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import {
  MdOutlineSpaceDashboard,
  MdAccountBalance,
  MdBarChart,
  MdOutlineFilter,
  MdOutlineContacts,
  MdOutlineCampaign,
  MdSettings,
  MdHistory,
  MdOutlineMarkunreadMailbox,
  MdOutlineRateReview,
  MdOutlineContactSupport,
  MdOutlineChatBubbleOutline,
  MdIntegrationInstructions
} from "react-icons/md";
import { FaChartLine } from "react-icons/fa";
import { FaCircleUser, FaPersonRunning } from "react-icons/fa6";
import { BiHardHat } from "react-icons/bi";

import "./DopeSidebar.scss"
import AgencyClientIcon from "../icons/AgencyClientIcon";
import AccountInformationIcon from "../icons/AccountInformationIcon";
import UsersIcon from "../icons/UsersIcon";
import ImageCompareIcon from "../icons/ImageCompareIcon";
import GraphBarIcon from "../icons/BarGraphIcon";
import { GrUserSettings } from "react-icons/gr";

const userNavInputs = [
  { name: "Overview", icon: <Icon as={MdOutlineSpaceDashboard} size={"18px"} />, path: "/overview", path_options: ["overview"] },
  { name: "Analysis & Tracking", icon: <Icon as={MdBarChart} size={"18px"} />, path: "/conversion_reports", path_options: ["conversion_reports", "qr_codes"] },
  { name: "Designs", icon: <Icon as={MdOutlineFilter} size={"18px"} />, path: "/designs", path_options: ["designs"] },
  { name: "Lists & Contacts", icon: <Icon as={MdOutlineContacts} size={"18px"} />, path: "/lists_and_contacts", path_options: ["lists_and_contacts", "lists", "contacts"] },
  { name: "Campaigns", icon: <Icon as={MdOutlineCampaign} size={"18px"} />, path: "/campaigns", path_options: ["campaigns"] },
  { name: "Automations", icon: <Icon as={MdSettings} size={"18px"} />, path: "/automations", path_options: ["automations"] },
  { name: "Mail History", icon: <Icon as={MdHistory} size={"18px"} />, path: "/mail_history", path_options: ["mail_history"] },
  // { name: "Pulse", icon: <Icon as={MdOutlineRequestQuote} size={"18px"} />, path: "/pulse", path_options: ["pulse"] },
  // { name: "Data Purchase", icon: <Icon as={MdOutlineRequestQuote} size={"18px"} />, path: "/data_purchase", path_options: ["data_purchase"] },
  // { name: "Learning Center", icon: <Icon as={MdOutlineFolderSpecial} size={"18px"} />, path: "/learning_center", path_options: ["learning_center"] },
];

const agencyNavInputs = [
  ...userNavInputs,
  { name: "My Clients", icon: <Icon as={AgencyClientIcon} size={"18px"} />, path: "/my_clients", path_options: ["my_clients"] },
];

const adminNavInputs = [
  {
    name: "Accounts",
    icon: <Icon as={AccountInformationIcon}
      size={"18px"} />,
    children: [
      { name: "All Accounts", path: "accounts", path_options: ["accounts"] },
      { name: "Automations", path: "automations", path_options: ["automations"] },
      { name: "Pricing & Features", path: "pricing", path_options: ["pricing"] },
      { name: "Payments & Credits", path: "payments_and_credits", path_options: ["payments_and_credits"] },
    ]
  },
  {
    name: "Content",
    icon: <Icon as={GrUserSettings}
      size={"18px"} />,
    children: [
      { name: "Learning Slideovers", path: "slideovers", path_options: ["slideovers"] },
    ]
  },
  {
    name: "Agency Center",
    icon: <Icon as={AgencyClientIcon}
      size={"18px"} />,
    children: [
      { name: "Agencies", path: "agencies", path_options: ["agencies"] },
      { name: "Kickbacks", path: "kickbacks", path_options: ["kickbacks"] },
    ]
  },
  {
    name: "Users",
    icon: <Icon as={UsersIcon}
      size={"18px"} />,
    children: [
      { name: "DOPE Team", path: "dope_team", path_options: ["dope_team"] },
      { name: "Users", path: "users", path_options: ["users"] },
    ]
  },
  {
    name: "Design",
    icon: <Icon as={ImageCompareIcon}
      size={"18px"} />,
    children: [
      { name: "Design Revisions", path: "design_revisions", path_options: ["design_revisions"] },
      { name: "Design Templates", path: "design_templates", path_options: ["design_templates"] },
    ]
  },

  {
    name: "Reporting",
    icon: <Icon as={GraphBarIcon}
      size={"18px"} />,
    children: [
      { name: "Batching", path: "batching_details", path_options: ["batching_details"] },
      { name: "Data Axle Usage", path: "reporting/data_axle_usage", path_options: ["reporting/data_axle_usage"] },
      { name: "Job Queue", path: "reporting/delayed_jobs", path_options: ["reporting/delayed_jobs"] },
      { name: "Informed Visibility", path: "reporting/informed_visibility", path_options: ["reporting/informed_visibility"] },
      { name: "Tasks", path: "task_runner", path_options: ["task_runner"] },
    ]
  },
  {
    name: "EDDM",
    icon: <Icon as={MdOutlineMarkunreadMailbox}
      size={"18px"} />,
    children: [
      { name: "Orders", path: "eddm_orders", path_options: ["eddm_orders"] },
    ]
  },
  {
    name: "IPAAS",
    icon: <Icon as={MdIntegrationInstructions}
      size={"18px"} />,
    children: [
      { name: "Templates", path: "subscription_templates", path_options: ["subscription_templates"] },
    ]
  },
];

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
          {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

const helpCenterClick = () => {
  window.open("https://www.dopemarketing.com/support-central", "_blank");
};

// TODO got to figure out correct high level layout, heights are breaking

const DopeSidebar = ({ expand, setExpand, userType = 'user', accountType = 'standard' }) => {
  const [navbarHeight, setNavbarHeight] = useState(68);
  const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight);

  const navigate = useNavigate();
  const location = useLocation();

  const userInputs = accountType === 'agency' ? agencyNavInputs : userNavInputs;
  const navInputs = userType === 'user' ? userInputs : adminNavInputs;

  const resizeHandler = () => {
    setSidebarHeight(window.innerHeight - navbarHeight);
  }

  useEffect(() => {
    offsetNavbarHeight();

    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, []);

  const offsetNavbarHeight = () => {
    const componentAElement = document.querySelector('.user-navbar');
    if (componentAElement) {
      const navHeight = componentAElement.clientHeight;
      setNavbarHeight(navHeight);
      setSidebarHeight(window.innerHeight - navHeight);
    }
  };

  const handleClick = (path) => {
    navigate(path);
  }

  const isSelected = (paths) => {
    const currentPath = location.pathname;
    return paths.some(path => currentPath.includes(path))
  }

  return (
    <Sidebar
      style={{ top: navbarHeight, height: sidebarHeight }}
      className="dope-sidebar"
      width={expand ? 200 : 56}
      collapsible
    >
      <Sidenav expanded={expand} defaultOpenKeys={['3']} appearance="subtle" className="margin-80-b" >
        <Sidenav.Body style={{ padding: "10px 0 0 0" }}>
          {navInputs.map((navInput, index) => {
            const isMenu = navInput.children instanceof Array;
            const selected = isMenu ? navInput.children.some(c => isSelected(c.path_options)) : isSelected(navInput.path_options);

            return (
              <Nav key={index}>
                {!isMenu ? (
                  <Nav.Item eventKey={index} className={`nav-item ${selected ? 'selected-nav' : ''}`} onClick={() => handleClick(navInput.path)}>
                    <div className="vertical-align row full-height">
                      <div className={`nav-icon ${selected ? 'selected-icon' : ''}`}>{navInput.icon}</div>
                      <div className={`nav-text ${selected ? 'selected-text' : ''}`}>{navInput.name}</div>
                    </div>
                  </Nav.Item>
                ) : (
                  <Nav.Menu key={index} title={navInput.name} icon={navInput.icon} className={'menu-nav-item'}>
                    {navInput.children.map((child, childIndex) => {
                      const childSelected = isSelected(child.path_options);
                      return (
                        <Nav.Item key={childIndex} onClick={() => handleClick(child.path)} className={`nav-item ${childSelected ? 'selected-nav' : ''}`}>
                          <div className="vertical-align row full-height">
                            <div className={`nav-text ${childSelected ? 'selected-text' : ''}`}>{child.name}</div>
                          </div>
                        </Nav.Item>
                      )
                    })}
                  </Nav.Menu>
                )}
              </Nav>
            )
          })}
        </Sidenav.Body>
      </Sidenav>
      {/*<NavToggle expand={expand} onChange={() => setExpand(!expand)} style={{position: "sticky"}} />*/}

      {userType === 'user' &&
        <div className="pad-l margin-16-b">
          <h6 className="header-6 non-bold color-text-secondary margin-8-b">Help Center</h6>
          <div className="label large margin-4-b clickable" onClick={helpCenterClick}>
            <Icon as={MdOutlineContactSupport} className="help-center-icon" /> Submit a Ticket
          </div>
          <div className="label large margin-4-b clickable" onClick={helpCenterClick}>
            <Icon as={MdOutlineChatBubbleOutline} /> Chat
          </div>
          <div className="label large"><Icon as={BiHardHat} className="help-center-icon" /> Product Updates</div>
        </div>
      }
    </Sidebar>
  );
}

export default DopeSidebar;
