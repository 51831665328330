import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@rsuite/icons";
import pick from "lodash/pick";
import DopeApi from "../services/DopeApi";
import { useCampaign } from "./campaignSlice";

import { Drawer, FlexboxGrid, Input, InputGroup, Loader } from "rsuite";

import DopeButton from "../ui/DopeButton";
import { MdAdd, MdOutlineFileUpload, MdOutlineSearch } from "react-icons/md";
import { timeSince } from "../utils/date";

import "./CampaignMailerSelect.scss";
import { handleFilterChange } from "../utils/fn";
import DopeFilterDelegator from "../ui/dopeFilters/DopeFilterDelegator";
import authApi from "../auth/authApi";
import HandwrittenCardBodyPreview from "../designs/HandwrittenCardBodyPreview";
import DopeLogo from "../icons/DopeLogo";
import accountAbility from "../auth/accountAbility";
import { useQuery2 } from "../hooks/useQuery";

const designApi = new DopeApi("design");
const taggingsApi = new DopeApi("taggings");

const mailerTypes = [
  { label: 'Postcard', value: 'postcards' },
  { label: 'Handwritten', value: 'handwritten_cards' },
  { label: 'Goodies', value: 'goodies' },
  { label: 'Hot Lead', value: 'hot_leads' },
];

const MailerTypeSelect = ({ isEddm, filters, setFilters, scopes, setScopes, searchDesigns }) => {

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    }
    handleFilterChange(filters, setFilters, tags, filterObject);
  }

  const filterInputs = [
    { title: "Mailer Type", type: "scopeArgs", options: mailerTypes, name: "by_mail_template_type" },
    { title: "Tags",
      getOptions: (name = "") => taggingsApi.getOptions( {
        valueKey: "tag_id",
        labelKey: "tag.name",
        filters: [{ field: "taggable_type", operator: "=", value: "Design" }, {field: "tags.account_id", operator: "=", value: authApi.getCurrentAccountId()}],
        scopes: [{ name: "search_tag_by_name", args: name }]
      }),
      placement: "auto",
      applyFilter: applyTagFilters,
      searchable: true
    },
  ]

  return (
    <div className="margin-16-b">
      {!isEddm && filterInputs.map((filterInput, index) => (
        <DopeFilterDelegator key={index} filterInput={filterInput} filters={filters} scopes={scopes} setScopes={setScopes} />
      ))}

      <InputGroup style={{width: "100%"}} className="margin-4-t">
        <Input onChange={value => searchDesigns(value)} placeholder="Search" />
        <InputGroup.Addon onClick={() => searchDesigns("")} style={{cursor: 'pointer'}}>
          <Icon as={MdOutlineSearch} />
        </InputGroup.Addon>
      </InputGroup>

    </div>
  );
};

const MailerList = ({ options, loading, onSelect, type }) => {
  const navigate = useNavigate();

  if (loading) {
    return <Loader center size="lg" />
  }

  const productName = (design) => {
    if (design.mail_template_type === 'PostcardTemplate') {
      if (design.mail_template.mailer_type === 'hot_lead') {
        return 'Hot Lead Postcard';
      } else {
        return `${design.mail_template.size} Postcard`;
      }
    } else if (design.mail_template_type === 'HandwrittenCardTemplate') {
      return 'Handwritten Card';
    } else if (design.mail_template_type === 'GoodieTemplate') {
      return 'Goodie';
    }
  };

  const actionLabel = (type) =>{
    if (type === 'GoodieTemplate') {
      return 'Add Goodie';
    }
    return 'Add Card';
  }

  return (
    <div className="mailer-list">
      {options.map((design) => {

        return (
          <div key={design.id} className="mailer-item">
            <FlexboxGrid justify="space-between" className="rs-form flexbox-split mailer-image-wrapper">
              <FlexboxGrid.Item colspan={12}>
                <div>
                  <img className="mailer-image front-image" width="100%" height="208px" src={design.mail_template.front_image_url} />
                </div>
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={12}>
                {design.mail_template.back_image_url && design.mail_template_type === 'PostcardTemplate' && (
                  <div>
                    <img className="mailer-image back-image" src={design.mail_template.back_image_url} />
                  </div>
                )}

                {design.mail_template_type === 'HandwrittenCardTemplate' && (
                  <div style={{background: 'white', overflow: 'hidden'}} className="mailer-image back-image">
                    <HandwrittenCardBodyPreview
                      font={design.mail_template.font}
                      body={design.mail_template.body}
                      signature={design.mail_template.signature}
                      limit={design.mail_template?.card?.characters || 500}
                    />
                  </div>
                )}

                {design.mail_template_type === 'GoodieTemplate' && (
                  <div style={{background: 'white', overflow: 'hidden', aspectRatio: "145/102"}} className="mailer-image back-image flex">
                    <DopeLogo className="full-width" />
                  </div>
                )}
              </FlexboxGrid.Item>
            </FlexboxGrid>

            <div className="mailer-text">
              <div>
                <div className="mailer-name">{design.name}</div>
                <div className="mailer-type-text">
                  <div>{productName(design)} · </div>
                  <div className="mailer-approved-text">Approved {timeSince(new Date(design.mail_template.updated_at))}</div>
                </div>
              </div>

              <div>
                <DopeButton
                  icon={<Icon as={MdAdd} style={{fontSize: "16px", marginRight: "4px", position: "relative", top: 1}} />}
                  props={{
                    buttonClass: "text-link",
                    onClick: () => onSelect(design),
                    label: actionLabel(design.mail_template_type),
                  }}
                />
              </div>
            </div>

          </div>
        );
      })}

      {options.length === 0 && (
        <div className="pad margin">
          <header className="header-4 margin-4-b">No approved designs ✅</header>
          <div className="help-text margin-16-b">
            In order to send mail you’ll need to have approved designs in your account. Save your campaign as a draft come back and schedule your campaign once your designs are ready!
          </div>

          <DopeButton
            icon={<Icon as={MdOutlineFileUpload} style={{fontSize: "16px", marginRight: "4px", position: "relative", top: 1}} />}
            props={{
              buttonClass: "filled-black",
              label: "Upload a design",
              onClick: () => navigate('/designs', { state: { createDesign: true } }),
            }}
          />
        </div>
      )}
    </div>
  );
};

const initialScopes = (campaign) => {
  let scopes = [{ name: 'approved' }];
  if (campaign && campaign.is_eddm) {
    scopes.push({ name: 'eddm' });
  } else {
    scopes.push({ name: 'non_eddm' });
  }

  if (!accountAbility.can("create", "PostcardTemplate", { mailer_type: "hot_lead" })) {
    scopes.push({ name: "exclude_hot_leads" });
  }

  return scopes;
}


// TODO: rename, this is just mailer select
const CampaignMailerSelect = ({ onSelect, onClose, open }) => {
  const [campaign, campaignActions] = useCampaign();

  const initialFilters = () => {
    let abilityFilters = [];
    if (!accountAbility.can("create", "Design", { mail_template_type: "GoodieTemplate" })) {
      abilityFilters.push({ field: "mail_template_type", operator: "!=", value: "GoodieTemplate" });
    }

    if (!accountAbility.can("create", "Design", { mail_template_type: "HandwrittenCardTemplate" })) {
      abilityFilters.push({ field: "mail_template_type", operator: "!=", value: "HandwrittenCardTemplate" });
    }

    return abilityFilters;
  }

  const accountPermissionFilters = initialFilters();

  const {
    tableProps,
    onSearchInput,
    onSearchClear,
    filters,
    setFilters,
    scopes,
    setScopes,
    loading,
  } = useQuery2({
    api: designApi,
    initialSearchScope: "name",
    initialFilters: accountPermissionFilters,
    initialScopes: initialScopes(campaign),
  });

  const handleClose = () => {
    onSearchClear();
    onClose();
    setFilters(accountPermissionFilters);
  }

  const handleSelectMailer = (mailer) => {
    onSelect({
      mail_template_type: mailer.mail_template_type,
      mail_template_id: mailer.mail_template.id,
      mail_template_name: mailer.name,
      status: 'draft',
      ...pick(mailer.mail_template, 'front_image_url', 'front_image_thumbnail_url', 'back_image_url', 'back_image_thumbnail_url', 'product_name', 'product_label', 'mailer_type'),
    });
    handleClose();
  };

  return (
    <>
      <Drawer
        size="sm"
        placement="right"
        open={open}
        onClose={handleClose}
      >
        <Drawer.Header className="hidden-break">
          <Drawer.Title>
            <div style={{ fontWeight: 600, fontSize: "22px"}}>What do you want to send?</div>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body className="custom-drawer-padding">
          <MailerTypeSelect
            isEddm={campaign && campaign.is_eddm}
            filters={filters}
            setFilters={setFilters}
            searchDesigns={onSearchInput}
            scopes={scopes}
            setScopes={setScopes}
          />
          <MailerList onSelect={handleSelectMailer} options={tableProps.data} loading={loading} />
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default CampaignMailerSelect;
