import React, { useState } from "react";

import DopeApi from "../services/DopeApi";

import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import DopeConfirmationModal from "../ui/DopeConfirmationModal";

import { Link } from "react-router-dom";
import authApi from "../auth/authApi";
import { MdCopyAll } from "react-icons/md";

const campaignsApi = new DopeApi('campaign');

const iconStyles = {
  marginRight: "16px",
  color: "#292B2E",
  fontSize: "18px",
};

const CampaignDropdown = ({ rowData: campaign, reloadTable, getUrl, getDisabled }) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmClone, setShowConfirmClone] = useState(false);

  const isAdmin = authApi.currentUserHasAdminPrivileges();

  const handleClickDelete = async () => {
    setLoading(true);
    await campaignsApi.destroy(campaign.id);
    await reloadTable();
    setLoading(false);
  };

  const handleClickClone = async () => {
    setLoading(true);
    await campaignsApi.postMember(campaign.id, 'clone');
    await reloadTable();
    setLoading(false);
  };


  console.log(campaign, isAdmin)

  const url = getUrl(campaign);
  const disabled = getDisabled(campaign);
  const linkDisabledClass = disabled ? "disabled" : "";

  const isDraft = campaign.status === "draft";
  const viewLabel = isDraft ? "Edit Campaign" : "View Campaign";

  const discardButton = isDraft && (
    <div onClick={() => setShowConfirmDelete(true)} className="vertical-align row clickable text margin-16-b label large bold"><TrashIcon style={iconStyles} /> Discard Draft</div>
  );

  const cloneButton = isAdmin && !campaign?.trigger_full_name && (
    <div onClick={() => setShowConfirmClone(true)} className="vertical-align row clickable text margin-16-b margin-16-t label large bold"><MdCopyAll style={iconStyles} /> Clone Campaign</div>
  );

  return (
    <>
      <Link to={url} disabled={disabled} className={`vertical-align row clickable margin-8-tb label large bold ${linkDisabledClass}`}><EditIcon style={iconStyles} /> {viewLabel}</Link>
      {cloneButton}
      {discardButton}

      <DopeConfirmationModal
        open={showConfirmDelete}
        title={`Are you sure you want to delete ${campaign.name} campaign?`}
        message="Once deleted we will not be able to restore anything that was already built."
        onConfirm={handleClickDelete}
        onCancel={() => setShowConfirmDelete(false)}
        confirmLabel="Yes, delete"
        cancelLabel="No, cancel"
        loading={loading}
      />

      <DopeConfirmationModal
        open={showConfirmClone}
        title={`Are you sure you want to clone the ${campaign.name} campaign?`}
        message="This will copy the campaign configuration without any of its mailers."
        onConfirm={handleClickClone}
        onCancel={() => setShowConfirmClone(false)}
        confirmLabel="Yes, clone"
        cancelLabel="No, cancel"
        loading={loading}
        />
    </>
  );
}

export default CampaignDropdown;
